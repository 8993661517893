import React from "react"
import Layout from '../components/layout'
import Head from '../components/head'

const IndexPage = () => {
    return (
        <Layout>
            <Head title="Home" />
            <div className="main-container">
                <h1 className="heading">Hello. <br />I am Tabs.</h1>
                <br />
                <h2 className="subheading">You've arrived at my corner of the internet. Condolences.</h2>

                <h3>I experiment with this site's code :)</h3>

                <ul className="follow-me">
                    <li><a href="https://ladyofcode.com/">Visit ladyofcode.com for my work</a><br /></li>
                    <li><div className="twitter-icon"></div><a href="https://twitter.com/ladyofcode">Follow @ladyofcode</a><br /></li>
                    <li><div className="instagram-icon"></div><a href="https://instagram.com/ladyofcode">Follow @ladyofcode</a></li>
                </ul>
            </div>
            
        </Layout>
    )
}

export default IndexPage
